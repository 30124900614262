#nav-bar-wrapper {
  display: flex;
  padding: 2px 0;
/*  justify-content: space-between;
  border-bottom: 2px solid black;
  align-items: flex-start;
  background-color: snow;*/
  
  align-items: center;
  justify-content: center;
  height: 5vh;
  /*margin-bottom: 10px;*/
  font-size: 1.5em;
  font-family: "Oleo Script", sans-serif;
  color: #93B5C6;
}

.dropdown {
  display: flex;
  flex-direction: column;
  /*border: 2px solid red;*/
  cursor: default;
  color:;
}

.dropdown-content {
  display: none;
  background-color: snow;
}

.dropdown:hover .dropdown-content {
  display: block;
}