#contact-wrapper {
  position: fixed;
  bottom: 10px;
  right: 0;

  // border: 1px solid black;
  background-color: #93B5C6;

  max-width: 250px;
  border-radius: 30px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


  #contact-close {
    position: fixed;
    bottom: 60px;
    right: 20px;   

    // border: 1px solid black;
    border-radius: 10px;
    padding: 0 5px;
    cursor: pointer;
 
    background-color: snow;
    color: #BD4B4B;

    // left: 5px
  }

  #contact-note {

    padding: 10px;


    font-style: italic;
    font-size: 0.8em;
    color: white;
    /*font-family: "Oleo Script", sans-serif;*/

  }
}