#culture-and-legend-wrapper {
	// border: 10px solid green;
	max-height: 95vh;
	overflow: scroll;
}

#culture-wrapper {
	font-family: "Open Sans";
	// overflow: scroll;

	#company-select-wrapper {
	}

	#culture-company {
		font-weight: bold;
	}

	#blurb-and-values {
		margin-top: 10px;
		border-radius: 10px;		
	}

	#culture-blurb {
		display: flex;
		flex-direction: column;
		background-color: snow;
		padding: 5px;
		border-radius: 10px;		
		font-size: 0.7em;

		max-height: 30vh;
		overflow: scroll;
		cursor: default;

		transition: max-height 0.4s ease, background-color 0.5s ease;
	
		.blurb-keyword {
			text-decoration: underline;
		}
	}

	#culture-link {
		// font-size: 0.8em;
		// margin-left: 100%;
		// border: 2px solid red;
		text-align: right;
		// font-style: italic;	
		font-weight: bold;
		a {
			font-size: 1.1em;
			color: teal;
		}
	}

	.used-value {
		color: #FA86BE;
	}

	#value-list-header {
		margin-top: 5px;
		// border: 2px solid red;
		// font-size: 1.2em;
		font-weight: bold;
		text-align: center;
	}	

	#company-values-list-wrapper {
		padding: 0 5px;	
		padding-bottom: 0px;	
	}

	#company-values-list {
	  columns: 2;
	  -webkit-columns: 2;
	  -moz-columns: 2;	


	  max-height: 75px;
	  overflow: scroll;	
	  // border: 2px solid red;
		// padding-bottom: 5px;	
	
	 .list-value {
		  white-space: nowrap;
		  // overflow: scroll;
		  overflow: hidden;
		  text-overflow: ellipsis;		 	
	 }
	}
}



#color-legend-wrapper {
	margin-top: 20px;  
	padding: 5px;
	// border: 2px solid red;
	border: 2px solid lightgrey;	
	border-radius: 10px;
	transition: background-color 0.2s;

	#legend-header {
		// border: 2px solid red;
		text-align: center;
		margin-bottom: 10px;
		font-size: 1.2em;
	}

	#color-item-box {
	  // columns: 2;
	  // -webkit-columns: 2;
	  // -moz-columns: 2;		

	  border-bottom: 2px dashed lightgrey;
	  padding-bottom: 5px;
	  display: flex;
	  justify-content: space-around;
	  align-items: flex-end;

	  .color-item-sub-box {
	  	// border: 2px solid blue;
	  	text-align: right;
	  	
	  }

	  #general-color-item-box {
	  	// border: 2px solid purple;
	  }

	  #color-item-box-divider {
	  	border: 1px solid lightgrey;
	  	// width: 10px;
	  	height: 50px;
	  	// flex-grow: 1;

	  }

		.color-legend-line {
		  white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  // display: block;
		  // line-height: 1em; /* a */
		  // max-height: 2em; /* a x number of line to show (ex : 2 line)  */			
		}
	}
}


#score-result-container {
	margin-top: 5px;
	#score-results-wrapper {
		display: flex;
		justify-content: space-evenly;
	
		.score-result {
			font-weight: bold;
			transition: color 0.3s;
		}
	}

	#score-result-suggestions {
		margin-top: 5px;
		font-size: 0.8em;
		font-style: italic;
	}
}














