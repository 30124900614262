#search-culture-wrapper {
  /*border: 2px solid black;*/
  // flex-grow: 3;
	width: 30%;
	min-width: 200px;
}

#search-wrapper {
	display: inline-block;
	// max-width: 30%;
	// width: 30%;
	width: 100%;
	// border: 2px solid red;
	// margin-bottom: 50px;

	#search-input-wrapper {
		display: flex;
		background-color: white;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
		
		#search-input {	
			font-size: 1.1em;
			width: 100%;
			height: 40px;
			border: none;
			padding: 0 10px;
			outline: none;

			white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;		
		}

		#search-icon {
			margin-right: 10px;
			cursor: pointer;
		}
	}

	.search-match-container {
		position: relative;

		.search-match-wrapper {
			position: relative;

			background-color: snow;
		  // box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 10px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);  
			// box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
			box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

			// border: 2px solid grey;
			margin: 5px 0;
			padding: 5px;
		}

		.search-match-name {
			font-size: 0.9em;
			color: gray;
		}

		#no-match-message {
			text-align: center;
			margin: 20px 30px;
			color: gray;
		}

		.copy-icon {
			position: absolute;
			bottom: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}
