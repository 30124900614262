$desaturated-dark-cyan: hsl(180, 29%, 50%);
$light-grayish-cyan-filters: hsl(180, 31%, 95%);
$dark-grayish-cyan: hsl(180, 8%, 52%);
$very-dark-grayish-cyan: hsl(180, 14%, 20%);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #008b8b;*/
  // background-color: $desaturated-dark-cyan
  // background-color: #d2f5e3;
  // background-color: #dbe3e5; // LIGHT
  background-color: #345B63; //DARK
  transition: background-color 0.5s ease
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
