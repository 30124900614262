#feedback-button {
	/*border: 4px solid gold;*/
	/*border-radius: 150%;*/
	height: 100%;
	/*width: 100%;*/

	padding: 3px;
	background-color: #0f4c75;
	cursor: pointer;
}

#feedback-icon {
	width: 30px;
	/*border: 2px solid red;*/
}



#feedback-form-popup {
	background-color: #0f4c75;
	/*border: 2px solid gold;*/
	display: flex;
	align-items: center;

	padding: 10px;

	border-radius: 10px;

	z-index: 2;
}


#feedback-input {
	border-radius: 5px;
	font-family: 'Abel', sans-serif; 
	/*font-size: 1em;*/
}


#submit-feedback {
	max-width: 80px;
	/*max-height: 50px;*/
	background-color: gold;
	padding: 10px 0;
	border: none;
	margin-left: 5px;

	font-weight: bold;
	border-radius: 5px
}