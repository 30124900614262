#editor-wrapper {
	// border: 2px solid green;
	margin: 0 20px;
	// flex-grow: 3;
	// width: 600px;
	// font-family: "Times New Roman"
	font-family: "Arial"
	
}

#content-editor-plus-csb-wrapper {
	// border-bottom: 5px solid transparent;	

	display: flex;

	width: 600px;
	height: 85%;
	max-height: 750px;	
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

	transition: border-color 0.2s
}

#editor-content-wrapper {
	transition: background-color 0.2s, border 0.2s;
	position: relative;
	// border: 2px solid red;
	width: 600px;

	max-height: 750px;
	overflow: scroll;

	// height: 85%;
	// box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

	height: 100%;

  #time-saved-marker {
  	position: absolute;
  	top: 10px;
  	left: 10px;
  	color: grey;
  	font-size: .8em;
  }

  #company-name-marker {
  	position: absolute;
  	top: 10px;
  	left: 60%;
  	color: grey;
  	font-size: .8em; 

  	font-weight: bold; 	
  }
	
	#body-pretty {
		border-top: 4px solid transparent; //DO NOT DELETE UNLESS ALSO DELETE IN #body
		transition: background-color 0.2s, border-color 0.2s;
		// height: 100%;
		position: absolute;
		// overflow: scroll;
		min-height: 100%;
		padding: 40px;
		padding-right: 5px;
		z-index: -1;
		background-color: snow;	
		// background-color: #345B63;
		// color: white;
		left: 0;
		right: 0;

		#paste-prompt-box {
			position: absolute;
			// border: 2px solid blue;
			// margin: 0 auto;
			top: 100px;
			left: 0;
			width: 100%;
			text-align: center;

			// color: lightgrey;
			font-size: 20px;

			#paste-prompt {
				margin-bottom: 20px;
				font-family: "American Typewriter", sans-serif;
			}
		}	
	}	

	#body {
		border-top: 4px solid transparent; //DO NOT DELETE UNLESS ALSO DELETE IN #body-pretty		
		// border: 3px solid green;
		min-height: 100%;
		white-space: pre-line;
		padding: 40px;
		padding-right: 5px;
		// padding-left: 0;
		outline: none;
		// height: 100%;
		// opacity: 0.3;
		caret-color: black;
		// background-color: snow;
		color: transparent;
		// visibility: hidden;
		// overflow: hidden;
		// position: relative;
		cursor: text;
		// margin-bottom: 10px;
		// z-index: -10;
		position: relative;

		// overflow: scroll;		
	}
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 95%;
  height: 95%;
  padding: 5px;

  // border: 2px solid green;
  border-radius: 10px;
  // display: relative;
  padding: 0px;
}

#demo-popup-close {
  position: absolute;
  top: -10px;
  right: -10px;   
  font-size: 1.5em;
  // border: 1px solid black;
  border-radius: 20px;
  padding: 0 5px;
  cursor: pointer;

  background-color: snow;
  color: #BD4B4B;

  // left: 5px
}

.modal {
	// border: 2px solid blue;
	height: 100%;

}

#video-player {
	// border: 2px solid red;
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

#example-button-box {
	position: absolute;
	// border: 2px solid blue;
	top: 200px;
	left: 0;
	width: 100%;
	text-align: center;	

	#example-button {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;	

		// border: 2px solid gold;
		border-radius: 10px;
		background-color: #93B5C6;
		width: 40%;
		height: 50px;

		font-size: 2em;
		font-family: "Oleo Script", sans-serif;
		// box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;				
		// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		// box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
		box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

		cursor: pointer;
	}
}		

#score-bar-wrapper {
	position: sticky;
	bottom: 0;
	left: 0;

	// border-bottom: 2px solid green;
	height: 30px;
	width: 100%;	
	transition: background-color 0.2s, border-color 0.2s;

	#score-bar-section {
		position: relative;

		width: 100%;
		height: 100%;
		// border: 2px solid green;
		margin: 0 auto;
		display: flex;
		align-items: center;
		
		#score-bar {
			height: 0px;
			margin: auto 0;
			margin-right: 5px;
			transition: width 0.5s ease-out;
			border-radius: 0 5px 5px 0;
		}

	}

	#score-fraction {
		// border: 2px orange
	}

	#position-halfway-mark-container {
		position: absolute;
		bottom: 0;
		left: 50%;

		height: 50%;
		width: 0px;
		border: 0.5px solid lightgrey;
		opacity: 0.5;
		// border-radius: 5px;
	}	

	#position-halfway-text {
		position: absolute;
		top: -10px;
		left: 48.5%;
		opacity: 0.5;	
	}
}	





#company-score-bar-wrapper {
	// border: 2px solid blue;
	height: 100%;
	width: 40px;	

	transition: background-color 0.2s, border-color 0.2s;
	border-right: 5px solid transparent;	
	// padding-right: 5px;

	#company-score-bar-section {
		position: relative;

		width: 100%;
		height: 95%;
		// border: 2px solid green;
		margin: auto 0;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;

		// border: 2px solid purple;
		text-align: center;

		#company-halfway-mark-container {
			position: absolute;
			top: 50%;
			right: 0;

			width: 50%;
			height: 0px;
			border: 0.5px solid lightgrey;
			opacity: 0.5;
			// border-radius: 5px;
		}	
	}

	#company-score-bar {
		width: 0px;
		// margin: 10;
		// margin-right: 5px;
		// margin-top: 10px;
		transition: height 0.5s ease-out;
		border-radius: 0 0 5px 5px;
		// border: 2px solid orange
	}

	#company-score-fraction {
		// border: 2px orange
	}
}	







#posting-connector-box {
	border: 2px solid gold;
	width: 0;
	height: 100px;

	// position: absolute;
	// bottom: 0;
	// left: 0;
}


#editor-name-wrapper {
	// display: flex;
	// border: 2px solid gold;
	background-color: snow;
	// max-width: 500px;
	// height: 20px;
	// background-color: snow;
	margin-bottom: 10px;	
	// position: relative;
	// z-index: 0;
	height: 40px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	// display: none;
	position: relative;

	#coverly-logo-editor {
		// display: none;

		// position: absolute;
		// top: 50px;
		// top: 5px;
		// left: 10px;
		// margin: 0 auto;
		// right: 10%;
		// z-index: 2;
  
	  font-size: 1.8em;
	  font-family: "Oleo Script", sans-serif;
	  color: #93B5C6;		
	  cursor: default;	
	}

	#naming-options {
		// margin: 5px 0;
		height: 100%;
		// border: 2px solid black;
		display: flex;
		justify-content: space-between;
		align-items: center;		

		#name {
			// height: 100%;
			outline: none;
			margin: 0 5px;
			// border: 2px solid pink;
			width: 100%;

			white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
			border: none;
			// text-align: center;
			font-weight: bold;
			font-size: 1.3em;
			// max-height: 100%;
			// max-width: 500px;
			// flex-grow: 2;
			z-index: 1;
			background-color: snow;
			color: black;
		}

		#cl-tags-wrapper {
			display: flex;
			justify-content: space-between;
			width: 100%;
			// border: 2px solid gray;
			.tag-select-wrapper {
				width: 49%;
			}
		}

		#editor-buttons-wrapper {
			display: flex;
			margin-right: 10px;
			// border: 2px solid yellow;

			.editor-button {
				border-radius: 3px;
				padding: 5px;
				margin: 5px auto;
				margin-left: 5px;
				color: white;
				font-weight: bold;
				cursor: pointer;
				// width: 30px;
			}	
		}	
		// margin-right: 30px;
	}

	#file-browser-wrapper {
		// position: absolute;
		// bottom: 50px;
		width: 100%;
		// left: 0;
		// z-index: -10;
	}	
}



#browser-dropkey {
	font-weight: bold;
	font-size: 20px;
	img {
		width: 10px;
	} 
}


[contenteditable] {
    // padding:12px; 
}
[data-placeholder]:empty:before {
     content: attr(data-placeholder);
     color: #888;
     font-style: italic;
}

.Select-menu-outer {
    z-index: 1000;
  }




.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

