.auth-wrapper {
	margin: 0 auto;
	margin-top: 20vh;
	width: 50%;
	max-width: 300px;


	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border: 2px solid black;
	border-radius: 10px;

	background-color: whitesmoke;

	z-index: 1;
	/*text-align: center;*/
}

.auth-wrapper input {
	margin: 5px 0;
}

.auth-header {
	margin: 10px 0;
}

.auth-form {
	/*text-align: right;*/
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0 auto;
	margin-right: 40px;
}

.auth-button {
	border: none;
	border-radius: 5px;
	/*background-color: #0f4c75;*/
	background-color: gold;
	color: black;
	padding: 5px;

	font-weight: bold;
	cursor: pointer;
}

#resend-confirmation-button {
	border: none;
	background-color: gold;
	border-radius: 3px;
	font-weight: bold;
	cursor: pointer;
}