#keywords-container {
	// border: 2px solid yellow;
	margin-bottom: 30px;
	// min-width: 300px;
	// flex-grow: 1;
	// order: 5;
	width: 30%;
	min-width: 200px;
	overflow: hidden;

	#keywords-sections-wrapper {
		display: flex;
		flex-direction: column;
		// justify-content: center;

		#industry-indicator {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.industry-divider	{
				width: 50%;
				height: 0;

				border-radius: 5px;

			}

			#industry-label {
				margin: 0 5px;
				font-weight: bold;
			}
		}

		#position-keywords-wrapper {
			// border: 2px solid green;
			// border-bottom: 2px solid lightgrey;
			// border-radius: 5px;
			// width: 120px;


		  .list-keyword {
			  white-space: nowrap;
			  overflow: hidden;
			  text-overflow: ellipsis;	
			  // border: 2px solid red;
		  }		
			
			#keywords-list {
			  // text-align: center;
			  // border: 2px solid red;
			  margin-top: 10px;
			  margin-bottom: 10px;
			  padding: 10px;
			  padding-top: 5px;
			  padding-bottom: 5px;

			  border-radius: 10px;

			  // overflow-wrap: break-word;
			  font-size: 0.9em;
			  // white-space: nowrap;
			  // overflow: hidden;
			  // text-overflow: ellipsis;		
			  // max-height: 1%;	  
			  cursor: default;
			
			  #keywords-placeholder {
			  	color: grey;
			  	// border: 2px solid green;
			  }
			}

			.keywords-present {
			  columns: 2;
			  -webkit-columns: 2;
			  -moz-columns: 2;	
			}

			.used-keyword {
				color: #00AF91;
				opacity: 0.5;
				font-weight: bold;
				// text-decoration: line-through green 2px;
			}			
		}

		#posting-skills-wrapper {
			// border: 2px solid blue;
			margin-top: 10px;
			border-radius: 10px;
			overflow: scroll;
			transition: height 0.5s ease;

			#posting-input {
				height: 100px;
				// width: 100%;
				// border: 2px solid red;
				background-color: snow;
				padding: 10px;
				border-radius: 10px;
				// margin-top: 20px;
				// margin-bottom: 5px;
				
				// padding-bottom: 20px;
	
				// font-size: 0.9em;
				// margin: 10px;
			}

			#posting-input:empty:before {
			    content: attr(data-placeholder);
			}			

			.used-skill {
				color: #FFCC29;
				opacity: 0.5;
				font-weight: bold;				
				// text-decoration: line-through green 2px;
			}		

			#posting-keywords-container {
				// border: 2px solid orange;
				// overflow: scroll;
				padding: 5px;
				padding-bottom: 10px;

				.posting-keywords-header {
					// margin: 0 auto;
					text-align: center;
					// border: 2px solid red;
					font-weight: bold;
					margin: 0px auto;
					margin-top: 10px;
				}

				.empty-posting-keywords-message {
					text-align: center;
					color: lightgrey;
					// border: 2px solid green;
				}

				.skills-list-container {
					max-height: 200px; // NEEDS MAX HEIGHT TO SCROLL OVERFLOW
					overflow: scroll;
				}

				.skills-list {
					
					// border: 2px solid green;
				  columns: 2;
				  -webkit-columns: 2;
				  -moz-columns: 2;	
				  // height: 50px;
				  // max-height: 100px;
				  overflow: scroll;	
				  // margin-bottom: 5px;	  
				}

				.list-skill {
				  white-space: nowrap;
				  overflow: hidden;
				  text-overflow: ellipsis;	
				}
			}
		}
	}
}




